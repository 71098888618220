<template>
    <o-data-lookup :data-object="dsIncidents" contextField="OrgUnitIdPath">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">
                
            </span>
        </template>
        <o-column field="ID" width="150"></o-column>
        <o-column field="OrgUnit" width="200"></o-column>
        <o-column field="Status" width="120"></o-column>
        <o-column field="Type" width="200"></o-column>
        <o-column field="ReportedBy" width="200"></o-column>
        <o-column field="Description" width="200"></o-column>
    </o-data-lookup>
</template>

<script setup>

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,        
});

const dsIncidents = $getDataObjectById("dsIncident_Incidents");
</script>
